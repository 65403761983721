/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Typography */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

:root {
  --univers: "Univers Flux", Helvetica Neue, Helvetica, Arial, sans-serif;

  --lhr: 0.8576388889;

  --p-lh: calc(var(--blu) * 2);
  --p-fs: calc(var(--p-lh) * var(--lhr));
  --p-fw: 500;

  --h3-lh: var(--p-lh);
  --h3-fs: var(--p-fs);
  --h3-fw: 500;

  --h2-lh: calc(var(--blu) * 4);
  --h2-fs: calc(var(--h2-lh) * var(--lhr));
  --h2-fw: 500;

  --h1-lh: calc(var(--blu) * 4);
  --h1-fs: calc(var(--h1-lh) * var(--lhr));
  --h1-fw: 500;

}

*, *::before, *::after {
  font-family: var(--univers);
  line-height: var(--p-lh);
  font-size: var(--p-fs);
  font-weight: var(--p-fw);
  --margin: calc(var(--blu) * 1);
}

p, .p, .p * {
  line-height: var(--p-lh);
  font-size: var(--p-fs);
  font-weight: var(--p-fw);
  --margin: var(--p-lh);
}

h3, .h3, .h3 * {
  line-height: var(--h3-lh);
  font-size: var(--h3-fs);
  font-weight: var(--h3-fw);
  --margin: var(--h3-lh);
}

h2, .h2, .h2 *, strong {
  line-height: var(--h2-lh);
  font-size: var(--h2-fs);
  font-weight: var(--h2-fw);
  --margin: var(--h2-lh);
}

h1, .h1, .h1 * {
  line-height: var(--h1-lh);
  font-size: var(--h1-fs);
  font-weight: var(--h1-fw);
  --margin: var(--h1-lh);
}

p, .p, h3, .h3, h2, .h2, h1, .h1 {
  margin-bottom: var(--margin);
}

.noMargin, .noMargin * {
  margin-bottom: 0;
}


/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Links */

a, .a {
  cursor: pointer;

  &:link, &:hover, &:active, &:visited, &:focus {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
