/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Preload UI Elements */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

body::after {
  position:absolute;
  width:0;
  height:0;
  overflow:hidden;
  z-index:-1;
  content:
          url("/assets/archive_image_view_off.svg")
          url("/assets/archive_list_view_on.svg")
          url("/assets/archive_image_view_off.svg")
          url("/assets/archive_image_view_on.svg");
}
