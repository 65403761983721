@use "sass:math";

// Globale
@import "styles/global/reset";
@import "styles/global/preload";
@import "styles/global/roots";
@import "styles/global/global";
@import "styles/global/typo";

// Components


// Media
@media all and (max-width: 35em)  {@import "styles/media/01_mobile";}
@media all and (min-width: 35em)  {@import "styles/media/02_tablet";}
@media all and (min-width: 55em)  {@import "styles/media/03_tablet_laptop";}
@media all and (min-width: 75em)  {@import "styles/media/04_laptop";}
@media all and (min-width: 120em) {@import "styles/media/05_desktop";}
//
@supports (-webkit-hyphens:none) {@import "styles/media/00_safari_only.scss";}