/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Roots */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

:root {
  --blu: 8px;
  --line: 1px;
  --lineP: calc(var(--p-fs) * 0.118);

  --blurSta: 0px;
  --blurEnd: 40px;

  --backSta:rgba(255, 255, 255, 0);
  --backEnd: rgba(255, 255, 255, 0.1);

  --blurTrans: 0.2s;
  --slideTrans: 1s;

  --black: hsla(0, 0%, 0%, 1);
  --grey: hsla(0, 0%, 80%, 1);
  --white: hsla(0, 0%, 100%, 1);
  --red:   hsla(0,100%,50%,1.00);

}
