/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
/* 1 / Mobile / XS / */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

:root {
  --media: XS_Mobile;
}

/* Project Cover Image Elements */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.header_container {
  .header_title {
    display: none;
  }
}


/* Info Page Elements */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.flux_info_container {
  .imprint {
    width: 100%;
  }
}

/* Info Page Elements */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.cases {
  .case {
    grid-template-columns: 1fr max-content;
    border-top: solid var(--line) var(--black);
    padding-top: calc(var(--blu) * 0.5);

    .title {
      order: 1;
    }

    .year {
      order: 2;
    }

    .tags {
      order: 3;
      grid-column: span 2;
      display: none;
    }

    > a {
      order: 4;
      grid-column: span 2;
    }
  }
}

/* Container Elements */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.edition_container  {
  .title {
    display: none;
  }
  .edition_list * {
    font-size: var(--p-fs) !important;
    line-height: var(--p-lh) !important;
  }
}