/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Global Resets */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

*, *::before, *::after {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

button, textarea, input, select, a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
}

i {
  font-style: unset;
}

b {
  font-weight: unset;
}

a {
  text-decoration: none;
}