/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Global */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Grid Elements */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

html {
  scroll-snap-type: y proximity;
  overflow-x: hidden;
  overscroll-behavior: none;
}

html, body {
  margin: 0;
  background: var(--white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  hyphens: auto;
}


/* Splash screen */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.splash {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(var(--vh) * 100);

  h1 {
    margin: 0;
    padding: 0;
  }
}


/* Project */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.slider-item {
  position: absolute;
  overflow: hidden;
  right: 0;

  flex-flow: column nowrap;
  align-items: flex-end;
}

.project {
  width: 100vw;
  padding-bottom: calc(var(--vh) * 100);
  pointer-events: none;

  > div {
    pointer-events: initial;
  }

}

.project.loading {
  padding-bottom: 0;
}


/* Navigation */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
.nav_container {
  --margin: calc(var(--blu) * 1);
  --transDur: 0.15s;
  opacity: 1;

  .left,
  .right {
    display: grid;
    position: fixed;
    top: var(--margin);
    z-index: 200;
    mix-blend-mode: lighten;
    transform: translateY(0);
    transition: transform 0.2s;
    transition-timing-function: ease-in-out;
    grid-gap: var(--blu);
  }

  .left {
    left: var(--margin);
    grid-template-columns: auto auto;
  }

  .right {
    right: var(--margin);
    grid-template-columns: auto;
  }

  &.footer {

    .left,
    .right {
      mix-blend-mode: unset;
    }

    .btn, {
      background: var(--black) !important;
      color: var(--red) !important;
    }
  }

  &.introVisible {
    .left, .right {
      transform: translateY(-150%);
    }
  }
}


/* Container Elements */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.header_container {
  position: relative;
  width: 100%;
  height: auto;
  background: var(--white);
  z-index: 10;
}

.loader_container {
  background: var(--white);
  width: 100vw;
  height: calc(var(--vh) * 50);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 60;
  transition: height 0.5s ease-out;

  img {
    width: calc(var(--blu) * 2);
    height: calc(var(--blu) * 2);
    opacity: 1;
    transition: opacity 0.5s ease-out;
  }
}

.loader_container.closed {
  height: 0;

  img {
    opacity: 0;
  }
}

.text_container {
  position: relative;
  width: 100%;
  min-height: calc(var(--vh) * 100);
  padding: var(--blu);
  background: var(--white);
  z-index: 60;

  &.Red {
    background: var(--red);
  }

  &.Grey {
    background: var(--grey);
  }
}

.edition_container {
  position: relative;
  background: var(--black);
  width: 100%;
  height: calc((var(--vh) * 100) + 1px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 30;

  .title {
    position: absolute;
    color: var(--white);
    top: calc(var(--blu) * 1.25);
  }
}

.footer_container {
  position: fixed;
  width: 100%;
  height: calc(var(--vh) * 100);
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--red);
}


/* Project Cover Image Elements */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.header_container {

  .header_title {
    --pad: calc(var(--blu) * 1);
    text-align: center;
    position: sticky;
    z-index: 100;
    width: 100%;
    color: var(--white);
    top: var(--pad);
    height: 0;
    padding: 0;

    .gradient {
      position: absolute;
      top: calc(0px - var(--pad));
      left: 0;
      width: 100%;
      height: calc(var(--blu) * 10);
      background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 100%);
      z-index: -100;
    }
  }

  .header_image {
    position: relative;
    display: block;
    width: 100%;
    overflow: auto;
    user-select: none;
  }

  img, video {
    display: block;
    width: 100%;
    min-height: calc(var(--vh) * 100);
    object-fit: cover;
  }
}

.header_navigation {
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 100;
  height: 0;

  > div {
    --third: calc(100% / 3);
    position: absolute;
    top: 0;
    width: var(--third);
    height: 100vh;
    color: var(--white);
    display: flex;
    align-items: center;
    padding: calc(var(--blu) * 0.5);
    cursor: pointer;
  }

  .desk_nav_last {
    left: 0;
    justify-content: left;

    :not(.bullet) {
      transform: translateX(calc(0px + (var(--blu) * 1)));
    }
  }

  .desk_nav_star {
    left: var(--third);
    justify-content: center;
    white-space: nowrap;
  }

  .desk_nav_next {
    right: 0;
    justify-content: right;

    :not(.bullet) {
      transform: translateX(calc(0px - (var(--blu) * 1)));
    }


  }

  .word,
  .bullet {
    display: none;
  }
}


/* Project Text Elements */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.project_tags {
  position: absolute;
  bottom: var(--blu);
  width: calc(100% - (var(--blu) * 2));
  margin-bottom: calc(0px - (var(--blu) * 0.3));

  .tag_link {
    position: relative;
    float: left;
    text-align: center;

    .tag_text::before {
      content: "•";
      color: transparent;
      display: inline-block;
      position: absolute;
      width: 100%;
      text-align: center;
      left: 0;
    }
  }

  .tag_link:hover {
    cursor: pointer;

    .tag_text {
      color: transparent;
    }

    .tag_text::before {
      color: var(--black);
    }
  }

  .tag_separator {
    padding-left: calc(var(--blu) / 2);
    padding-right: calc(var(--blu) / 2);
    float: left;
  }

  .tag_separator:first-of-type {
    display: none;
  }
}

.project_tags:not(.footnote_container .project_tags) {
  pointer-events: none;
}

.project_tags:hover {
  .tag_separator {
    opacity: 0;
  }
}

.footnote_container {
  left: 0;

  .project_tags {
    .tag_separator {
      display: block;
    }
  }
}


/* Image Gallery Elements */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.gallery_container {
  display: flex;
  flex-flow: row wrap;
  background: var(--white);
  position: relative;
  z-index: 50;

  .gallery_text {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: var(--blu);
    padding-bottom: calc(var(--blu) * 0.78);
    mix-blend-mode: difference;

    p {
      margin: 0;
      color: var(--white);
    }
  }

  > div {
    position: relative;
    background: var(--white);
  }

  .fullwidth {
    width: 100%;
  }

  .two {
    width: 50%;
  }

  .four {
    width: 25%;
  }

  .left {
    width: 100%;
    padding-right: 50%;

    .gallery_text {
      width: 50%;
      right: 0;
    }
  }

  .right {
    width: 100%;
    padding-left: 50%;

    .gallery_text {
      width: 50%;
      left: 0;
    }
  }

  img {
    width: 100%;
    object-fit: cover;
    display: block;
  }

  video {
    width: 100%;
    display: block;
  }
}


/* Edition List Elements */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.edition_list,
.next_container {
  color: var(--white);
  text-align: center;
}

.edition_list {

  .title {
    padding-bottom: var(--blu);
  }

  .item {
    display: block;
  }

  .item:hover {
    cursor: pointer;
    text-decoration: underline;

    .bg_img {
      display: block;
    }
  }

  .bg_img {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: calc(var(--vh) * 100);
    object-fit: cover;
    z-index: -10;
    pointer-events: none;
  }
}


/* Footer Elements */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.next_container {
  color: var(--black);
  letter-spacing: 0;
}

.next_container:hover {
  cursor: pointer;

  .link {
    text-decoration: underline;
  }
}

.footnote_container {
  position: absolute;
  width: 100%;
  bottom: calc(var(--blu) * 1);
  text-align: center;
}


/* Party Elements */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.idle_overlay {
  display: none;
  overflow: hidden;

  .red {
    position: fixed;
    width: 100%;
    height: 100%;
    background: var(--red);
    z-index: 100;
    mix-blend-mode: multiply;
  }

  .cloud {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 110;
    mix-blend-mode: luminosity;

    video {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.idle_overlay.active {
  display: block;
}


/* Info Page Elements */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.info {
  background: var(--red);
  position: absolute;
  min-height: 100%;
  width: 100%;
  z-index: 600;

  h3 {
    padding: 0 var(--blu) var(--blu) var(--blu);
    display: block;
  }
}

.flux_info_container,
.flux_archive_container {
  position: relative;
}

.flux_info_container {
  padding: var(--blu);

  .imprint {
    width: calc(50% - var(--blu));

    p {
      strong {
        display: block;
        margin-top: calc(var(--blu) * 1);
        margin-bottom: calc(var(--blu) * 0.5);
      }
    }
  }
}

.flux_info_container:nth-child(3) {
  padding-bottom: calc(var(--blu) * 3);
}

.contact {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: var(--h2-lh);
}

.cases {
  display: flex;
  flex-flow: column nowrap;
  padding-inline: var(--blu);

  .heading {
    display: none;
  }

  .case {
    width: 100%;
    hyphens: none;
    display: grid;
    grid-template-columns: 50% 1fr max-content;

    .tags {
      .tag {
        &.active {
          text-decoration: underline;
        }
      }
    }

    .year {
      text-align: right;
    }

    .images {
      width: 100%;
      height: 0;
      overflow: hidden;
      cursor: pointer;
      padding-top: 0;
      padding-bottom: 0;

      transition-property: padding;
      transition-duration: 0.3s;

      .image_row {
        display: flex;
        flex-flow: row nowrap;
        width: 100%;

        div {
          margin-top: calc(var(--blu) * 0.35);

          img {
            width: 100%;
          }
        }
      }
    }

    .title a,
    .tags div,
    .year div {
      position: relative;
      display: inline-block;
      padding-top: calc(var(--blu) * 0.15);
      margin-top: calc(0px - (var(--blu) * 0.15));
    }

    &:hover {
      .title {
        a {
          text-decoration: underline;
        }
      }
    }

    > a {
      grid-column: span 3;
    }
  }
}

.closeCurrent {
  position: fixed;
  top: var(--blu);
  right: var(--blu);
  z-index: 900;

  .line {
    background: red !important;
  }
}


/* Project Index Elements */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

#list_navigation_container {
  position: relative;
  padding-inline: var(--blu);
  margin-bottom: calc(var(--blu) * 2);
}

.flux_archive_container {
  padding-bottom: calc(var(--blu) * 2.5);

  .list_navigation {
    position: absolute;
    width: calc(var(--blu) * 2);
    height: calc(var(--blu) * 2);
    bottom: 0;
    right: var(--blu);

    &.image_view,
    &.list_view {
      background-image: url("/assets/archive_list_view_off.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      cursor: pointer;
    }

    &.image_view {
      &:hover {
        background-image: url("/assets/archive_list_view_on.svg");
      }
    }

    &.list_view {
      background-image: url("/assets/archive_image_view_off.svg");

      &:hover {
        background-image: url("/assets/archive_image_view_on.svg");
      }
    }

  }

  .list_navigation_title {
  }

}


/* Are.na Elements */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.arena {
  padding-inline: var(--blu);

  .arena-blocks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: var(--blu);
    grid-row-gap: var(--blu);

    * {
      margin: 0;
      padding: 0;
      border: none;
      position: unset;
      width: 100%;
    }

    iframe,
    img {
      background: var(--black);
    }
  }
}

.arena-block {
  overflow: hidden;

  a {
    position: relative;
    display: inline-block;
    width: unset;
    padding: 0 0 0 0.6em;
  }

  a:hover {
    color: transparent;
  }

  a::before {
    content: "•";
    color: var(--black);
    position: absolute;
    width: 100%;
    left: 0;
  }

  a:hover::before {
    text-align: center;
  }
}

.arena-block.aspect {
  position: relative;
  padding-top: calc(100% / 16 * 9);

  iframe {
    position: absolute;
    top: 0;
    height: 100%;
  }
}

.arena-block.wide {
  grid-column: span 2;
}


/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Portfolio */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.portfolio {

  .slider-wrapper {
    display: none;
  }

  .section {
    height: calc(var(--vh) * 100);
    padding: var(--blu);
    position: relative;
    background: var(--red);
  }

  .signature {
    z-index: 10;
    position: relative;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  #index_navi {
    --side: calc(var(--blu) * 3);
    position: fixed;
    width: var(--side);
    height: var(--side);
    top: var(--blu);
    right: var(--blu);
    background: var(--black);
    border-radius: var(--side);
    z-index: 610;

    border: none;
    appearance: none;
    user-select: none;
    font: inherit;
    cursor: pointer;

    &:hover {
      transform: scale(90%);
    }
  }


  /* Header Elements */
  /* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

  #header {

    .next {
      position: absolute;
      bottom: var(--blu);
      left: 50%;
      z-index: 100;
      transform: translate(-50%, -50%);
      height: var(--blu);
    }

    .idle_overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;

      .cloud {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  #header:hover {
    cursor: none;

    .next {
      position: fixed;
    }
  }

  #header:not(:hover) {
    .next {
      top: unset !important;
      left: 50% !important;
    }
  }

  /* Intro Elements */
  /* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

  #intro {
    height: auto;
    min-height: calc(var(--vh) * 100);
  }


  /* Team Elements */
  /* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

  #team,
  #portraits {
    height: calc(var(--vh) * 50);
  }

  #portraits {
    display: flex;
    padding: 0;
    background: var(--black);
    -ms-overflow-style: none;
    scrollbar-width: none;

    .person {
      position: relative;
      padding: var(--blu);
      width: 100%;
      height: 100%;

      .head {
        color: var(--white);
        mix-blend-mode: difference;
        position: absolute;
        z-index: 10;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  #portraits::-webkit-scrollbar {
    display: none;
  }

  #partner {
    background: var(--white);

    > div:last-of-type {
      position: absolute;
      bottom: var(--blu);

      p {
        margin: 0;
      }
    }
  }


  /* Project Elements */
  /* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

  .text_container {
    .project_text {
      padding-top: 0;
    }
  }


  /* Footer Elements */
  /* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

  #voila {
    height: calc(var(--vh) * 100);
    padding: 0;

    .sign_off {
      position: absolute;
      top: var(--blu);
      width: 100%;
      padding-left: var(--blu);
      padding-right: var(--blu);
      text-align: center;
    }

    .idle_overlay {
      display: block;
      position: absolute;
      background: var(--red);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;

      .cloud {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .loader {
      background: url("/assets/loader.svg");
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: var(--side);
      height: var(--side);
      --side: calc(var(--blu) * 4);
    }

    .sign_off,
    .footnote_container,
    .loader {
      z-index: 100;
    }

  }
}


/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Global */

.btn {
  --side: calc(var(--blu) * 3.4);
  background: black;
  padding: calc(var(--blu) * 1);
  color: white !important;
  display: inline-block;
  height: var(--side);
  min-width: var(--side);
  cursor: pointer;
  overflow: hidden;
  line-height: calc(var(--blu) * 1.7) !important;
  text-decoration: none !important;

  transition: border-radius var(--transDur);

  * {
    text-decoration: none !important;
  }

  span {
    line-height: calc(var(--blu) * 1.8);
    pointer-events: none;
  }

  &:not(.square) {
    padding: calc(var(--blu) * 1) calc(var(--blu) * 1.5);
    border-radius: calc(var(--blu) * 4);
  }

  &.trans {
    display: inline-block;
    position: relative;
    width: var(--side);
    white-space: nowrap;

    span {
      display: inline-block;
      white-space: nowrap;

      &:nth-child(1) {
        &.adjust {
          transform: translateX(-25%);
        }
      }

      &:nth-child(2) {
        max-width: 0;
        opacity: 0;
        transition: max-width var(--transDur);
      }
    }
  }

  &.outline {
    background: none;
    color: black !important;
    border: solid var(--line) black;
    line-height: calc(var(--blu) * 1.6) !important;
  }

  &.prev,
  &.next {
    &::after {
      --side: calc(var(--blu) * 1);
      --rotate: 45deg;
      content: "";
      display: block;
      width: var(--side);
      height: var(--side);
      border-top: solid var(--line) black;
      border-right: solid var(--line) black;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-75%, -50%) rotate(45deg);
    }

    &.prev {
      &::after {
        transform: translate(-25%, -50%) rotate(-135deg);
      }
    }
  }

  &.white {
    background: white !important;
    color: black !important;
  }
}

.closeCurrent {

  .line {
    display: block;
    background: white;
    width: var(--lineP);
    height: calc(var(--side) * 0.6);
    position: absolute;
    top: 50%;
    left: 50%;

    &:nth-child(1) {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:nth-child(2) {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &:hover {
    .line {
      height: calc(var(--side) * 0.5);
    }
  }
}

.flux {
  .main {
    .groupContainer {
      &:not(:first-child) {
        padding-top: calc(var(--blu) * 1);
      }
    }
  }
}

.blurContainer,
.blurContainerHover {
  --blur: var(--blurSta);
  position: relative;
  backdrop-filter: blur(var(--blur));
  -webkit-backdrop-filter: blur(var(--blur));
  background: var(--backSta) !important;
  transition: opacity var(--blurTrans), backdrop-filter var(--blurTrans), -webkit-backdrop-filter var(--blurTrans), background var(--blurTrans);

}

.blurContainer,
.blurContainerHover:hover {
  --blur: var(--blurEnd);
  background: var(--backEnd) !important;
}
