/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
/* 2 / Tablet / S / */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

:root {
  --media: S_Tablet;
  --blu: 9px;

  --h1-lh: calc(var(--blu) * 8);
  --h2-lh: calc(var(--blu) * 5);
}

/* Project Cover Image Elements */
/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */


.header_navigation {
  > div {
    div {
      --dur: 0.25s;
      transition: transform var(--dur), opacity var(--dur);
    }

    :not(.bullet) {
      opacity: 0;
    }

    &:hover {
      :not(.bullet) {
        opacity: 1;
        transform: translateX(0);
      }

      .bullet {
        opacity: 0;
      }
    }

  }

  .word,
  .bullet {
    display: unset;
  }

  .arrow {
    display: none;
  }
}


/* –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Global */

.btn {

  &.square {
    &:hover {
      border-radius: calc(var(--blu) * 4);
    }
  }

  &:hover {
    cursor: pointer;

    &.trans {
      width: auto;

      span {
        &:nth-child(1) {
          transform: translateX(0);
        }

        &:nth-child(2) {
          max-width: calc(var(--blu) * 10);
          opacity: 1;
        }
      }
    }
  }

  &.rotate {
    &:hover {
      transform: rotate(10deg);
    }
  }
}
